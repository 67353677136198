import React from 'react';
import './App.css';

function App() {
  return (
    <>
    <div className="App">
      <header className="App-header">
        <i class="fas fa-tools fa-3x"></i>
        <p>
          Oldal építés alatt
        </p>        
      </header>
    </div>
      </>
  );
}

export default App;
